<template>
	<div class="backups">
		<AlertDialog :title="alert.title" :message="alert.message" :active="alert.active" :ok="alert.ok">
			<v-btn @click="alert.active--;cancelBackup()" v-if="!alert.ok" text>Ja</v-btn>
			<v-btn @click="alert.active--" v-if="!alert.ok" text>Nein</v-btn>
		</AlertDialog>
		<DenseContainer>
			<v-row>
				<v-col cols="12">
					<v-card elevation="4" outlined shaped class="rounded-card">
						<v-card-actions>
							<v-btn @click="startBackup" color="primary" :disabled="isLoading || isBackupRunning"><v-icon left>mdi-cloud-sync</v-icon> Backup manuell starten</v-btn>
							<v-btn @click="cancelBackupConfirm" color="red" :disabled="isLoading || !isBackupRunning"><v-icon left>mdi-cloud-sync</v-icon> Backup abbrechen</v-btn>
						</v-card-actions>
					</v-card>
				</v-col>
				<v-col cols="12">
					<v-card elevation="4" outlined shaped class="rounded-card">
						<v-data-table
							@click:row="rowClick"
							:loading="isLoading"
							:headers="headers"
							:items="backupsFiltered"
							:disable-filtering="false"
							:single-select="true"
							class="elevation-1"
							v-model="currentBackups"
							item-key="id"
							:default-footer="true">
							<template v-slot:[`item.backup.state`]="{ item }">
								<div class="status-holder">
									<v-tooltip left>
										<template v-slot:activator="{ on, attrs }">
											<v-avatar :color="getItemColor('pending', item)" size="16" class="mr-1" v-bind="attrs" v-on="on">
												<v-icon size="10" dark>mdi-alarm</v-icon>
											</v-avatar>
										</template>
										<span>Ausstehend</span>
									</v-tooltip>
									<v-tooltip left>
										<template v-slot:activator="{ on, attrs }">
											<v-avatar :color="getItemColor('starting', item)" size="16" class="mr-1" v-bind="attrs" v-on="on">
												<v-icon size="10" dark>mdi-ray-start</v-icon>
											</v-avatar>
										</template>
										<span>Gestartet</span>
									</v-tooltip>
									<v-tooltip left>
										<template v-slot:activator="{ on, attrs }">
											<v-avatar :color="getItemColor('waiting', item)" size="16" class="mr-1" v-bind="attrs" v-on="on">
												<v-icon size="10" dark>mdi-sync</v-icon>
											</v-avatar>
										</template>
										<span>Wartend auf Backup-Fertigstellung</span>
									</v-tooltip>
									<v-tooltip left>
										<template v-slot:activator="{ on, attrs }">
											<v-avatar :color="getItemColor('downloading', item)" size="16" class="mr-1" v-bind="attrs" v-on="on">
												<v-icon size="10" dark>mdi-download</v-icon>
											</v-avatar>
										</template>
										<span>Backup heruntergeladen</span>
									</v-tooltip>
									<v-tooltip left>
										<template v-slot:activator="{ on, attrs }">
											<v-avatar :color="getItemColor('uploading', item)" size="16" class="mr-1" v-bind="attrs" v-on="on">
												<v-icon size="10" dark>mdi-cloud-upload</v-icon>
											</v-avatar>
										</template>
										<span>Backup hochgeladen</span>
									</v-tooltip>
								</div>
							</template>
							<template v-slot:[`item.backup.pendingDate`]="{ item }">
								<span v-html="item.backup.pendingDate && item.backup.state == 'pending' ? formatDate(new Date(item.backup.pendingDate)) : '-'">
								</span>
							</template>
							<template v-slot:[`item.backup.startDate`]="{ item }">
								<span v-html="item.backup.startDate && item.backup.state != 'pending' ? formatDate(new Date(item.backup.startDate)) : '-'"></span>
							</template>
							<template v-slot:[`item.backup.endDate`]="{ item }">
								<span v-html="item.backup.endDate && (item.backup.state == 'success' || item.backup.state == 'failed') ? formatDate(new Date(item.backup.endDate)) : '-'"></span>
							</template>
							<!-- Suchfilter -->
							<template v-slot:top>
								<v-text-field
									v-model="searchTerm"
									label="Suchbegriff"
									class="mx-4"
								></v-text-field>
							</template>
						</v-data-table>
						<!-- Legende -->
						<v-card-text>
							<v-row>
								<v-col>
									<p class="legend">
										<v-avatar color="grey" size="16" class="mr-1"></v-avatar> Ausstehend
									</p>
								</v-col>
								<v-col>
									<p class="legend">
										<v-avatar color="blue" size="16" class="mr-1"></v-avatar> Derzeit aktiv
									</p>
								</v-col>
								<v-col>
									<p class="legend">
										<v-avatar color="green" size="16" class="mr-1"></v-avatar> Erfolgreich
									</p>
								</v-col>
								<v-col>
									<p class="legend mb-0">
										<v-avatar color="red" size="16" class="mr-1"></v-avatar> Fehlgeschlagen
									</p>
								</v-col>
							</v-row>
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>
		</DenseContainer>
	</div>
</template>

<script>
import { firestore, functions } from '../main';
import { format } from 'date-fns'
export default {
	data() {
		return {
			isLoading: false,
			alert: {
				title: "",
				message: "",
				active: 0,
				ok: true
			},
			searchTerm: "",
			backups: [],
			currentBackups: [],
			firestoreListener: null,
			headers: [
				{ text: 'Typ', align: 'start', sortable: true, value: 'type', class: 'subtitle-1' },
				{ text: 'ID', align: 'start', sortable: true, value: 'id', class: 'subtitle-1' },
				{ text: 'Name', value: 'name', sortable: true, class: 'subtitle-1' },
				{ text: 'Anstehend seit', value: 'backup.pendingDate', sortable: true, class: 'subtitle-1' },
				{ text: 'Gestartet', value: 'backup.startDate', sortable: true, class: 'subtitle-1' },
				{ text: 'Beendet', value: 'backup.endDate', sortable: true, class: 'subtitle-1' },
				{ text: 'Status', value: 'backup.state', sortable: true, class: 'subtitle-1' },
			],
			allStates: ["pending", "starting", "waiting", "downloading", "uploading", "success", "failed"]
		}
	},
	computed: {
		isBackupRunning() {
			for (const backup of this.backups) {
				if (backup.backup.state === "pending" || backup.backup.state === "waiting" || backup.backup.state === "downloading" || backup.backup.state === "uploading") {
					return true
				}
			}
			return false
		},
		backupsFiltered() {
			const filteredBackups = []
			if (this.searchTerm && this.searchTerm != "") {
				for (const backup of this.backups) {
					if (backup.name.indexOf(this.searchTerm) !== -1) {
						filteredBackups.push(backup)
					} else if (backup.id.indexOf(this.searchTerm) !== -1) {
						filteredBackups.push(backup)
					} else if (backup.backup.state === this.searchTerm) {
						filteredBackups.push(backup)
					}
				}
			} else {
				filteredBackups.push(...this.backups)
			}
			return filteredBackups
		}
	},
	methods: {
		async startBackup() {
			this.isLoading = true
			const startBackupProjectplaceProjectsManual = functions.httpsCallable("startBackupProjectplaceProjectsManual")
			const response = await startBackupProjectplaceProjectsManual()
			if (response.data.error) {
				this.alert.title = "Backup starten"
				this.alert.message = "Beim Starten des Backups ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut."
				this.alert.ok = true
				this.alert.active++
			}
			this.isLoading = false
		},
		cancelBackupConfirm() {
			this.alert.title = "Backup abbrechen"
			this.alert.message = "Möchten Sie das aktuelle Backup wirklich abbrechen? Bereits durchgeführte oder gestartete Backups sind davon nicht betroffen."
			this.alert.ok = false
			this.alert.active++
		},
		async cancelBackup() {
			this.isLoading = true
			const cancelBackupProjectplaceProjectsManual = functions.httpsCallable("cancelBackupProjectplaceProjectsManual")
			const response = await cancelBackupProjectplaceProjectsManual()
			if (response.data.error) {
				this.alert.title = "Backup abbrechen"
				this.alert.message = "Beim Abbrechen des Backups ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut."
				this.alert.ok = true
				this.alert.active++
			}
			this.isLoading = false
		},
		formatDate(date) {
			return format(date, "kk:mm") + "<br>" + format(date, "dd.MM.yyyy")
		},
		getItemColor(forState, item) {
			const state = item.backup.state
			if (state == "failed") {
				return "red"
			}
			if (state == "success") {
				return "green"
			}
			if (state == forState) {
				return "blue"
			}
			const stateIndex = this.allStates.indexOf(state)
			const forStateIndex = this.allStates.indexOf(forState)
			if (stateIndex >= forStateIndex) {
				return "green"
			}
			return "grey"
		},
		rowClick(item, row) {

		}
	},
	mounted() {
		console.log('Backups.vue mounted');

		if(this.$store.state.permissions.pages.indexOf('backups') === -1){
			this.$router.push('/');
			return;
		}
		this.isLoading = true
		this.firestoreListener = firestore.collection("backups").onSnapshot((snapshot) => {
			this.backups = []
			for (const backup of snapshot.docs) {
				const data = backup.data()
				if (data.backup.pendingDate) {
					data.backup.pendingDate = data.backup.pendingDate.toDate().getTime()
				}
				if (data.backup.startDate) {
					data.backup.startDate = data.backup.startDate.toDate().getTime()
				}
				if (data.backup.endDate) {
					data.backup.endDate = data.backup.endDate.toDate().getTime()
				}
				if (!data.backup.type) {
					data.backup.type = 'Projectplace Projekt'
				}
				this.backups.push(data)
			}
			this.isLoading = false
		})
	},
	destroyed() {
		if (this.firestoreListener) {
			this.firestoreListener()
			this.firestoreListener = null
		}
	}
}
</script>
<style scoped>
.legend {
	white-space: nowrap;
}
.status-holder {
	white-space: nowrap;
}
</style>